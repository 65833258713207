<template>
  <div class="pa-2">
    <v-card class="mx-auto" max-width="800">
      <v-card-title>利用規約</v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <h2 class="ma-4">はじめに</h2>
        <p class="ml-4">
          キワ・アート・アンド・デザイン株式会社（以下、「当社」といいます）が提供するサービスの利用規約を以下の通り定めます。利用者は、予め本規約に同意した上でサービスを利用するものとみなされますので、本サービスをお使いのお客様は下記の内容を事前にお読みいただきますようお願い申し上げます。
        </p>
        <h2 class="ma-4">第１条（総則）</h2>
        <ol class="ml-4" type="1">
          <li>
            利用規約（以下、「本規約」といいます）は、当社と次条に定める利用者との間の一切の関係に適用されるものとします。
          </li>
          <li>
            利用者は本規約に同意の上、本サービスを利用するものとみなされます。
          </li>
          <li>
            当社は本規約を、利用者への予告なしに改定できるものとします。変更後の本規約は、過去の規約に優先して適用されるものとし、本サービス上に掲載した時点をもって効力を生じるものとします。変更後の本サービスの利用により、利用者は変更後の規約に同意したものとみなされます。
          </li>
          <li>
            本規約は日本語文及び英語文で作成されていますが、日本語文とその英語文の間に不一致又は相違があるときは、日本語文の表記を正しいものとします。
          </li>
        </ol>
        <h2 class="ma-4">第２条（用語の定義）</h2>
        <ol class="ml-4" type="1">
          <li>
            「利用者」とは、本サービスを利用するすべての者のことを指します。
          </li>
          <li>
            「コンテンツ」とは、記事やその他のテキスト、店舗情報、画像、音声、動画、プログラムなどの本サービス上のあらゆる情報（当社あるいはユーザーより提供されたものであるかを問わない）を指します。
          </li>
          <li>
            「投稿」とは、本サービスの投稿フォームを通じてコンテンツをアップロードすることを指します。
          </li>
        </ol>
        <h2 class="ma-4">第３条（本サービスの目的と内容）</h2>
        <p class="ml-4">
          本サービスでは、当社が収集した充電器情報を参考として活用していただくことを主な目的と内容としています。
        </p>
        <h2 class="ma-4">第４条（会員情報について）</h2>
        <p class="ml-4">
          本サービスのうち、会員登録が必要なサービスを利用する場合は、利用者は、以下の条件をお守りください。
        </p>
        <ol class="ml-4" type="1">
          <li>
            利用者が、会員登録をする場合には、本サービスが提供する会員情報登録画面より、当社の指定する書式においてインターネット回線を通じて当社所定の情報を送信する方法により行うものとします。
          </li>
          <li>
            当社は前号の情報が問題なく送信された場合に当社会員として承諾したものとしますが、当社は、利用者が過去に本規約に違反したものである場合、本契約に違反するおそれがあると当社が判断する場合その他当社が不適切と判断した場合についてはその会員登録申込を承諾しないこともあります。
          </li>
          <li>
            利用者は、利用者自身の情報として真実かつ正確な情報を会員情報として入力するものとします。
          </li>
          <li>
            登録情報が真実かつ正確でないと当社が判断した会員登録（アカウント）に関しては、会員の許諾なしに当社の判断で削除することができるものとします。
          </li>
          <li>
            本サービスを通じて登録された会員情報については公開・非公開にかかわらず当社が保有するものとします。
          </li>
          <li>
            会員が入力した情報の公開については会員が一切の責任を負うものとします（個人情報の書き込みなどの行為は行わないでください）。
          </li>
          <li>
            15歳未満の者が本サービスを利用する場合、保護者の同意を得てください。
          </li>
        </ol>
        <h2 class="ma-4">第５条（個人情報の取り扱い）</h2>
        <p class="ml-4">
          当社は、会員の個人情報を当社
          <router-link :to="{ name: 'PrivacyPolicy' }"
            >プライバシーポリシー</router-link
          >に従い、適切に取り扱います。
        </p>
        <h2 class="ma-4">第６条（会員資格）</h2>
        <p class="ml-4">
          本サービスで以下の各号に該当すると当社が判断した場合、当社は会員登録を取り消すことがあります。
        </p>
        <ol class="ml-4" type="1">
          <li>不適切な情報を掲載している場合</li>
          <li>パスワードの不正利用があった場合</li>
          <li>会員情報に虚偽がある場合</li>
          <li>本規約に反する行為があった場合</li>
          <li>その他、当社が不適切と判断する相当する事由があった場合</li>
        </ol>
        <h2 class="ma-4">第７条（会員のパスワード）</h2>
        <ol class="ml-4" type="1">
          <li>
            本サービスでログイン時に利用するパスワード（本サービスと連携する外部サービスのパスワードを含みます。以下同じ。）に関しては、会員が自身の責任下において管理するものとします。
          </li>
          <li>
            会員は、容易に第三者に推測されないパスワードを設定すること、第三者に対しパスワードを公開しないこと、複数人が使用するコンピュータまたは携帯電話上で本サービスを利用する際にはログアウトすることなどを遵守し、パスワードの管理について一切の責任を持つものとします。
          </li>
          <li>
            本サービス外でパスワードが第三者へ漏洩などするなどの理由で、パスワードが不正利用されたとしても当社は一切責任を負わないものとします。
          </li>
          <li>
            会員は会員登録時に利用したパスワードを第三者に譲渡、または使用させることはできません。
          </li>
          <li>
            当社は、登録されたパスワードによって本サービスの利用があった場合、会員本人が利用したものとみなし、当該利用によって生じた結果およびそれに伴う一切の責任については、会員に帰属するものとします。
          </li>
          <li>
            会員が本条に反して当社または本サービスに損害を与えた場合、会員がすべての責任を負うものとします。
          </li>
        </ol>
        <h2 class="ma-4">第８条（退会）</h2>
        <ol class="ml-4" type="1">
          <li>
            退会は本サービスが定める所定の場所から申請をし、当社へデータが送信された時点で退会申請を受け付けたものとします。
          </li>
          <li>
            当社は、会員が、本サービスを退会した際に、本サービスを通じて投稿したコンテンツを継続して保有する義務を負わないものとします。
          </li>
          <li>
            会員の退会により、他の利用者に不利益・損害が発生した場合においても当社はその責任を負いません。
          </li>
        </ol>
        <h2 class="ma-4">第９条（免責）</h2>
        <p class="ml-4">
          本サービスの利用は、利用者ご自身の責任においてご利用いただきます。また、次の項目に関して、予め同意するものとします。
        </p>
        <ul class="ml-4">
          <li>
            情報の不保証
            <p>
              当社は、掲載された情報の内容の正確性については一切保証しません。また、当サイトに掲載された情報・資料を利用、使用、ダウンロードするなどの行為に関連して生じたあらゆる損害等についても、理由の如何に関わらず、一切責任を負いません。
            </p>
          </li>
          <li>
            口コミ（書き込み内容）の保証
            <p>
              当社は、掲載された口コミの内容、画像その他コンテンツに関していかなる保証もいたしません。また、掲載された書き込み内容、画像その他コンテンツによって生じた損害（利用者が作成した各種コンテンツによるコンピュータ・ウィルス感染被害なども含みます）や、
              利用者同士のトラブル等に対し、当社は一切の補償および関与をいたしませんので、予めご了承ください。
            </p>
          </li>
          <li>
            リンク先の保証
            <p>
              当社は、利用者によってリンクされたホームページ及び外部トラックバックによりリンクされたサイトに関して、いかなる保証もいたしません。また、リンク先で生じた損害や、利用者同士のトラブル等に対し、当社は一切の補償および関与をいたしませんので、予めご了承ください。
            </p>
          </li>
          <li>
            外部サービス
            <p>
              会員は、当社と連携する外部サービスの内容、運用の変更により、又は会員が外部サービスの全部もしくは一部を利用できなくなることにより、本サービスの全部又は一部の利用ができなくなる場合があることを予め了承するものとします。当社は、外部サービスに関連して会員に発生した損害について、一切の責任を負わないものとします。
            </p>
          </li>
          <li>
            損害賠償責任の制限
            <p>
              利用者は、①利用者が本サービスを利用したこと、または利用できなかったこと、②不正アクセスや不正な改変がなされたこと、③本サービス中の他の利用者による行為、④第三者のなりすまし行為、⑤その他本サービスに関連する事項に起因または関連して生じた一切の損害（精神的苦痛または逸失利益その他の金銭的損失を含む一切の不利益）に関して、当社が損害賠償責任を負わないことに同意します。また、当社が損害賠償責任を負う場合についても、当社に故意または重大な過失がある場合を除いて、当社は、当該利用者が直接かつ現実に被った損害についてのみ損害賠償責任を負うものとし、特別な事情から生じた損害等（損害発生につき予見し、または予見し得た場合を含む。）については責任を負わないものとします。
            </p>
          </li>
        </ul>
        <h2 class="ma-4">第１０条（コンテンツの削除）</h2>
        <ol class="ml-4" type="1">
          <li>
            本サービスは、利用者からの自己責任に基づく投稿によって成り立っているコミュニティサービスですが、利用者に快適にご利用いただくため、下記に該当するまたは該当するおそれのある書き込み・画像その他コンテンツが発見された場合、予告無く、書き込み（コメント、コミュニティでの発言）の全部又は一部を削除する場合があります(ただし、当社は投稿されたコンテンツの内容を監視する義務および削除義務を負うものではありません)。なお、削除対象に該当するか否かは、ユーザーからのお店情報の提供をうけ、当社が作成したお店情報に関するコンテンツを含め、全て当社が判断いたします。また、下記に該当していない場合でも、本サービスの管理運営の都合上、やむを得ず書き込みを削除する場合もありますので、予めご了承ください。
            <ol class="ml-4" type="i">
              <li>他者を誹謗中傷するもの（個人、お店への誹謗中傷）</li>
              <li>
                衛生管理面の問題等、しかるべき当局へご連絡いただく内容のもの
              </li>
              <li>内容の確認が困難と思われるもの（根拠のないもの）</li>
              <li>
                店舗などへの個人的な激しいクレーム、およびトラブルや支払いに関するもの
              </li>
              <li>本サービスの趣旨、または口コミ対象と関係のないもの</li>
              <li>
                自殺、自傷行為、薬物乱用等を美化・誘発・助長する恐れのある言葉、その他の表現を含むもの
              </li>
              <li>
                民族・人種・性別・年齢等による差別につながる表現を含むもの
              </li>
              <li>
                胸や臀部等のアップや性器が露出した画像、映像、イラスト、絵画その他当社がわいせつ的と判断する表現を含むもの
              </li>
              <li>
                暴力的、グロテスクな写真、または利用者が不快に感じる画像、言葉その他の表現を含むもの
              </li>
              <li>
                )第14条の禁止行為に該当するものその他、本サービスを妨げる等、当社が不適当と判断したもの
              </li>
            </ol>
          </li>
          <li>
            前項の措置により利用者に不利益・損害が発生した場合においても、当社はその責任を負いません。また前項の定めにしたがって、当社が行った行為に関する、質問、苦情は一切受け付けておりませんのでご了承ください。
          </li>
          <li>
            第1項の規定により、当社が削除対象と判断した投稿（以下、「対象投稿」といいます）については、当社は下記の措置をとることができるものとします（ただし、当社が以下の措置をとる義務を負うものではありません）。
            <ol class="ml-4" type="i">
              <li>対象投稿の投稿内容の一部を改変すること</li>
              <li>
                対象投稿を行った利用者（以下、「対象投稿者」といいます）への本サービスの提供を一定期間停止すること
              </li>
              <li>対象投稿者の会員登録を取り消すこと</li>
              <li>
                他の利用者による本サービスの適正な利用を促すため、対象投稿（または、対象投稿および対象投稿者）を適宜の方法により本サービス内外で開示すること
              </li>
            </ol>
          </li>
          <li>
            利用者が、コンテンツの削除依頼をした場合（お店が自らの店舗に関するコンテンツの削除依頼をした場合を含む）においても、当社は削除依頼のあったコンテンツの削除を約束するものではありません。
          </li>
        </ol>
        <h2 class="ma-4">第１１条（本サービスの一時的な中断等）</h2>
        <p class="ml-4">
          当社は利用者に事前の告知なく、以下の理由にて本サービスの提供を中断することがあります。また当社は本サービスの停止による利用者の不利益・損害に関して、一切の責任を負わないものとします。
        </p>
        <ol class="ml-4" type="1">
          <li>本サービスのシステムの保守、点検、修理をするとき</li>
          <li>本サービスの稼働のための設備の保守、点検をするとき</li>
          <li>火災、停電などによるシステム機器のトラブルがあったとき</li>
          <li>
            天変地異、戦争、動乱、労働争議などによる本サービスの提供ができなくなったとき
          </li>
          <li>
            その他、運用上、技術上、当社が本サービスの提供の中断を行う必要があると判断したとき
          </li>
        </ol>
        <h2 class="ma-4">第１２条（著作権等）</h2>
        <ol class="ml-4" type="1">
          <li>
            利用者が本サービスに投稿したコンテンツに関する著作権は、原則、投稿した利用者またはコンテンツの創作者に帰属するものとします。ただし、利用者は、利用者が本サービスを利用し投稿したコンテンツに関して、当社および、当社から再利用許諾を受けた第三者に対して、無償で非独占的にかつ永続的に、複製、編集、改編、掲載、転載、公衆送信、上映、展示、提供、販売、譲渡、貸与、翻訳、翻案などができる権利および二次的著作物に関する原著作権者の権利（著作権法２１条ないし２８条の権利をいい、商用利用を含む）を許諾したものとします。また、利用者は、当社及び当社の指定する者に対して著作者人格権を行使しないものとします。
          </li>
          <li>
            前項に定める著作権を除き、本サービスに関する著作権その他の権利は当社又は当社に利用許諾した第三者に帰属し、利用者は、本サービスに関するコンテンツを無断で複製、編集、改編、掲載、転載、公衆送信、上映、展示、提供、販売、譲渡、貸与、翻訳、翻案、二次利用等することはできません。
          </li>
          <li>
            利用者は、利用者が本サービスに投稿したコンテンツが、第三者の知的財産権（著作権、著作隣接権、意匠権、実用新案権、商標権、特許権、ノウハウが含まれるがこれに限定されない。）、パブリシティ権、プライバシー権その他一切の権利を侵害するものではないことを保証するものとします。
          </li>
          <li>
            当社または第三者が利用者のコンテンツを利用したことによって受けた利用者の損害については、当社では一切の保証をいたしません。
          </li>
          <li>
            利用者が本サービスに掲載されている第2項に定めるコンテンツを利用して利益を得た場合には、当社は利用者に対しその利益相当額の金額を請求できる権利を有するものとします。
          </li>
        </ol>
        <h2 class="ma-4">第１３条（禁止行為）</h2>
        <p class="ml-4">
          当社は、利用者が本サービスを利用するに際して、次の各号に該当するまたは該当するおそれがある行為を行うことを禁止します。利用者が本条に違反したと当社が判断する場合、当社は、当該利用者に対して①違反の是正を行うこと、②一時的に本サービスを利用停止とすること、③会員登録を抹消すること、④違反事実を本サービス内外で開示することがあります（ただし、当社はその義務を負うものではありません）。本条の措置により利用者に不利益・損害が発生した場合においても、当社はその責任を負いません。また本条の定めにしたがって、当社が行った行為に関する、質問、苦情は一切受け付けておりませんのでご了承ください。
        </p>
        <ol class="ml-4" type="1">
          <li>本規約に違反すること</li>
          <li>
            当社の認めない第三者より金品などの対価を受けることを目的としたお店情報を投稿すること
          </li>
          <li>法令または公序良俗に反すること</li>
          <li>犯罪的行為に結びつくことまたは犯罪的行為を助長すること</li>
          <li>
            当社、他の会員または第三者の知的財産権（著作権、意匠権、実用新案権、商標権、特許権、ノウハウが含まれるがこれに限定されない）を侵害すること
          </li>
          <li>有害なプログラム・スクリプト等を送信、投稿する行為</li>
          <li>他の利用者または第三者に不利益を与えること</li>
          <li>
            営利を目的としたものや個人的な売買・譲渡を持ちかける内容、宣伝行為(当社の同意がある場合を除く)
          </li>
          <li>
            他の利用者または第三者に営利を目的とした勧誘行為（各種保険の勧誘行為並びにこれに類するものを含む）、またはそれに繋がると当社が判断した行為
          </li>
          <li>
            宗教活動（勧誘行為、布教活動を含む）を行う行為、またはそれにつながると当社が判断した行為
          </li>
          <li>
            アダルトサイト、ワンクリック詐欺サイト、ウィルス等の有害なコンピュータプログラム等を流布させることを目的とするサイト等当社が不適切と判断するサイトに誘導すること（単にリンクを張る行為も含む）
          </li>
          <li>
            ねずみ講、チェーンメール、MLM（マルチレベルマーケティング）、リードメール等の第三者を勧誘する内容を投稿すること
          </li>
          <li>
            本サービスと競合するサービスの提供、運営を自ら行い、または第三者をして行わせる行為並びにそれらのサービスへ他の利用者を勧誘する行為(当社の同意がある場合を除く)
          </li>
          <li>
            本サービスと競合するサービスに利用することを目的とした行為（情報収集等を目的とした行為を含む）
          </li>
          <li>当社または第三者になりすます行為</li>
          <li>当社からのサポートメール、私信を転載する行為</li>
          <li>
            １つのユーザーＩＤを複数人で利用する行為または１人で複数のユーザーＩＤを保有する行為
          </li>
          <li>通常利用の範囲を超えてサーバーに負担をかける行為</li>
          <li>
            本サービスの運営を妨げること、または、当社の信用を毀損すること
          </li>
          <li>その他、当社が不適当と判断すること</li>
        </ol>
        <h2 class="ma-4">第１４条（通知・連絡）</h2>
        <p class="ml-4">
          利用者は、当社に連絡をする場合、当社が指定するフォームおよび電子メールにての連絡を行うものとします。
          本サービス掲載上の連絡先以外から通知に関してはお受けしかねます。
          当社からの利用者に対する連絡は、本サービス上での掲示、または利用者への電子メールなどにより行うものとします。
          ただし、利用者から正確な連絡先の提供がなされていない場合の不利益に関しては、当社は一切責任を負いません。
        </p>
        <h2 class="ma-4">第１５条（権利義務等の譲渡）</h2>
        <ol class="ml-4" type="1">
          <li>
            利用者は、本規約上の地位に基づく一切の権利義務を、当社の事前の書面による承諾なく、第三者に譲渡もしくは貸与し、または担保に供してはならないものとします。
          </li>
          <li>
            当社は、本サービスに関する事業を合併、事業譲渡その他の事由により第三者に承継させる場合には、当該事業の承継に伴い、本規約上の地位、本規約に基づく権利、義務、会員情報および投稿されたコンテンツを当該事業の承継人に譲渡することができるものとし、利用者は、かかる譲渡について本項において予め同意したものとします。
          </li>
        </ol>
        <h2 class="ma-4">第１６条（分離可能性）</h2>
        <ol class="ml-4" type="1">
          <li>
            本規約の規定の一部が法令に基づいて無効と判断されても、本規約のその他の規定は有効とします。
          </li>
          <li>
            本規約の規定の一部がある利用者との関係で無効とされ、または取り消された場合でも、本規約はその他の利用者との関係では有効とします。
          </li>
        </ol>
        <h2 class="ma-4">第１７条（準拠法と裁判管轄）</h2>
        <p class="ml-4">
          本規約は、日本法を準拠とし、解釈されるものとします。また本規約に関し当社と利用者の間の訴訟が生じた場合、東京地方裁判所を第一審の専属管轄裁判所とします。
        </p>

        <div class="ml-4 mt-12">
          <p>
            住所：〒113-0021 東京都文京区本駒込6-15-18クリエーション六義園1・2階
          </p>
          <p>
            <ExtarnalLink
              url="https://kaad.jp/%e3%81%8a%e5%95%8f%e3%81%84%e5%90%88%e3%82%8f%e3%81%9b%ef%bd%9ccontact-us/"
              linkText="お問い合わせフォーム"
              linkEventLabel="company contact form"
            />
          </p>
          <p>キワ・アート・アンド・デザイン株式会社</p>
        </div>
        <p>2020年12月1日制定</p>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import ExtarnalLink from "@/components/ExtarnalLink.vue";

export default {
  components: { ExtarnalLink },
  metaInfo: {
    title: "利用規約",
    meta: [
      {
        vmid: "description",
        name: "description",
        content: "利用規約を掲載しています。",
      },
    ],
  },
};
</script>